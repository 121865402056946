body,
button,
input {
  font-family: "Jersey 10", sans-serif;
  margin: 0;
  font-size: 18px;
}

.App {
  background-repeat: repeat; /* This will tile your background image */
  min-height: 100vh; /* Optional: Ensure the div takes at least the full height of the viewport */
  width: 100%;
  margin: 0;
  padding: 0;
}

.kingdom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  margin: 10px;
}

.kingdom img {
  max-width: 100px; /* Limit image size, keeping the pixel art aesthetic */
  margin-bottom: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-size: 1.7em;
}

/* src/App.css */
.map-container {
  overflow: hidden;
  width: 100%; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  position: relative;
  user-select: none;
  cursor: grab;
}

.map-container:active {
  cursor: grabbing;
}

.map {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("./assets/grass-texture.svg");
  background-size: 8px; /* Smaller size to enhance the 'vastness' */
  background-repeat: repeat;
  transition: transform 0.5s ease-out;
}

.map:active {
  cursor: grabbing;
}

/* Adding basic tooltip styling */
.kingdom-marker {
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0.2em;
}

/* src/App.css */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.popup-inner {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.coordinates {
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  font-size: 16px;
  z-index: 10;
}

.container {
  position: relative;
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.box {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  color: white;
  width: 40%;
  height: 30%;
}

.box img {
  max-width: 100%;
  margin-bottom: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.map h3,
.map p {
  font-size: 5em;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  padding: 8px;
}

.player-list {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 10px;
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
}

a {
  color: #007bff;
  text-decoration: none;
}

.war {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.war-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
